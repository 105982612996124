import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    woodlandImage: file(relativePath: { eq: "woodland.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithVanInCityImage: file(
      relativePath: { eq: "locksmith-van-in-city.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function WoodlandPage({ data }) {
  return (
    <MainLayout
      title="Fast Mobile Locksmith in Woodland, CA | 916-995-0135 | 24 HR"
      description="24 hour locksmith services for lockouts, change locks, residential, commercial and car locksmith in Woodland, CA. Call now for free estimate. Low rates."
      offset
    >
      <ContactHeading background={data.woodlandImage.childImageSharp.fluid}>
        <h1 className="text-center">Locksmith Woodland</h1>
        <h2 className="text-center font-weight-light">
          24 Hour Locksmith in Woodland, CA
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section">
        <h2 className="text-center">Full Service Locksmith in Woodland, CA</h2>
        <Row>
          <Col xs={12} md={{ span: 7, order: 2 }} lg={6} xl={8}>
            <p className="indent mb-md-0">
              With more than a decade in the locksmith industry, Swift Locksmith
              continues to be the most reliable and trusted local locksmith in
              Woodland, CA and the nearby areas. Each one of our technicians are
              licensed, professional, friendly and available 24/7 so you can
              always rely on us to help you with any locksmith need at any time.
              We respond to every call that we receive urgently no matter if
              it's an emergency call or not. We customize each service based on
              your individual needs as well as to accommodate your budget. From
              car lockouts, duplicate keys, lock repairs or installing a new
              security system for your property or business, you can trust Swift
              Locksmith to provide you with a customized solution at an
              affordable price.
            </p>
          </Col>
          <Col
            xs={12}
            md={5}
            lg={{ span: 5, order: 1 }}
            xl={4}
            className="col-sm-pad"
          >
            <Img
              fluid={data.locksmithVanInCityImage.childImageSharp.fluid}
              alt="locksmith-van-in-city"
            />
          </Col>
        </Row>
      </Container>
      <Container as="section">
        <Row>
          <Col xs={12} sm={9} md={6} lg={5} className="mb-3 mx-auto">
            <h2>Residential Locksmith Services in Woodland, CA</h2>
            <ul className="mb-0">
              <li>House, Bedroom Lock Out Services</li>
              <li>Lock Repair Services</li>
              <li>Door Knob Lock Installation</li>
              <li>Duplicate House Keys</li>
              <li>High Security Locks</li>
              <li>Master Key System Installation</li>
              <li>Door Lock Rekey Services</li>
              <li>Lock Repair Services</li>
            </ul>
          </Col>
          <Col xs={12} sm={9} md={6} lg={5} className="mb-3 mx-auto">
            <h2>Automotive Locksmith Services in Woodland, CA</h2>
            <ul className="mb-0">
              <li>Vehicle Lock Outs</li>
              <li>Broken Key Extraction</li>
              <li>Ignition Rekey</li>
              <li>Duplicate Car Keys</li>
              <li>Chip Keys Cut Services</li>
              <li>Transponder Keys Programmed</li>
              <li>Lost Car Key Replacement</li>
              <li>Replace Keyless Entry Remotes</li>
            </ul>
          </Col>
          <Col xs={12} sm={9} md={6} lg={5} className="mb-3 mb-md-0 mx-auto">
            <h2>Commercial Locksmith Services in Woodland, CA</h2>
            <ul className="mb-0">
              <li>Business Rekey</li>
              <li>Lock Repairs</li>
              <li>Replace Lost Keys</li>
              <li>Master Key system Installation</li>
              <li>Keyless Access System</li>
              <li>Deadbolts, High Security Deadbolts</li>
              <li>Key Entry Knobs</li>
              <li>Office Lock Out Service</li>
            </ul>
          </Col>
          <Col xs={12} sm={9} md={6} lg={5} className="mx-auto">
            <h2>Emergency Locksmith Services in Woodland, CA</h2>
            <ul className="mb-0">
              <li>Car, House, Office Lockouts</li>
              <li>Bedroom, Storage, Garage Lockouts</li>
              <li>Emergency Rekey Services</li>
              <li>Emergency Lock Repair Services</li>
              <li>Broken Key Extraction</li>
              <li>Replacement of Lost Car Keys</li>
              <li>Lock Picking</li>
              <li>Mail Box Lockouts</li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container as="section" className="mb-5">
        <h2>Choosing Swift Locksmith in Woodland, CA</h2>
        <p className="indent mb-0">
          We are committed to providing the best customer service to all of our
          customers and we want to be your local locksmith that you can count on
          for years to come. When you call us, you will always have the right
          technician for the job. Our team of locksmith technicians are trained
          in every aspect of locksmith services and up to date with the latest
          technologies to make sure that we can always provide you with the best
          locksmith solutions. Swift locksmith technicians are prompt, friendly
          and honest so we make sure to disclose all costs before starting the
          job. If you are looking for reliable locksmith services and quality
          materials at competitive pricing, give us a ring today and see what we
          can do for you.
        </p>
      </Container>
    </MainLayout>
  )
}

export default WoodlandPage
